* {
    margin: 0;
    padding: 0
}

#root {
    width: 480px;
}

.nav-profile {
    padding-top: 0.5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 0.9em;
}

.card {
    width: 350px;
    background-color: #FFF9E8;
    border-color: #555153;
    border-radius: 0;
    cursor: pointer;
    transition: all 0.5s;
}

.card-header {
    background-color: none;
    border-bottom: none;
}

.image img {
    transition: all 0.5s
}

.card:hover .image img {
    transform: scale(1.5)
}

.btn {
    margin-top: 10px;
    height: 40px;
    width: 200px;
    border-radius: 5px;
    border-color: #1A5D0A;
}

.name {
    font-size: 22px;
    font-weight: bold;
}

.idd {
    font-size: 14px;
    font-weight: 600
}

.idd1 {
    font-size: 12px
}

.number {
    font-size: 22px;
    font-weight: bold;
    color: #1A5D0A;
}

.follow {
    font-size: 12px;
    font-weight: 500;
    color: #444444
}

.btn1 {
    height: 40px;
    width: 150px;
    border: none;
    background-color: #ffffff;
    color: #aeaeae;
    font-size: 15px;
}

.text span {
    font-size: 13px;
    color: #545454;
    font-weight: 500;

}

.icons i {
    font-size: 19px
}

hr .new1 {
    border: 1px solid
}

.join {
    font-size: 14px;
    color: #a0a0a0;
    font-weight: bold
}

.date {
    background-color: #ccc
}

.userLabel {
    text-align: left;
    float: left;
    font-size: 0.8em;
}

.gap {
    margin-bottom: 1rem;
    padding-bottom: 2rem;
    text-align: left;

}

.text-right {
    display: inline;
    text-align: center;
}

h4 {
    text-align: center;
    font-size: 1.6em;
    color: #525A5E;
}

.form-group {
    text-align: right;
}

.form-group a {
    text-decoration: none;
    color: #1A5D0A;
}

.section-title {
    margin: 0 3rem;
    border-bottom: 1px solid #525A5E;
    margin-bottom: 20px;
}

.profile-back-btn {
    /*width: 100%;*/
    border-radius: 0;
    color: #999999;
    background-color: #fff;
    border-width: 2px;
    border-color: #999999;
}

.nav-tabs .nav-item, .nav-tabs .nav-link  {
    color: #525A5E;
}

.nav-tabs .nav-link.active {
    color: #1A5D0A;
    border: 0px solid #000;
    border-bottom: 2px solid #1A5D0A;
}
