/***    General     ***/
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Brandon Grotesque", "Roboto", sans-serif
}

body {
    margin: 0 auto;
    height: 100%;
}

.m-5-auto {
    margin: 5rem auto
}

.primary-button {
    margin-bottom: 0.2rem;
    margin-top: 0.5rem;
    margin-right: 1rem;
    padding: .3rem;
    width: 10rem;
    background: none;
    border: none;
    color: #fcbd26;
    font-size: 2rem;
    transition: all .5s;
    cursor: pointer;
    text-transform: capitalize
}

/***    Landing Page     ***/
.main-title,
.main-para {
    color: #f1f1f1
}

.main-title {
    padding-top: 10rem;
    font-size: 5rem;
    font-family: 'Fascinate', cursive;
    text-transform: uppercase
}

.main-para {
    font-size: 2.5rem;
    text-Transform: capitalize
}

#log_btn span {
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

#log_btn span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

#log_btn:hover span {
    padding-right: 25px;
}

#log_btn:hover span:after {
    opacity: 1;
    right: 0;
}

#reg_btn span {
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

#reg_btn span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

#reg_btn:hover span {
    padding-right: 25px;
}

#reg_btn:hover span:after {
    opacity: 1;
    right: 0;
}

/***    Login Page     ***/
h2 {
    font-weight: 300
}

form {
    display: inline-block;
    background: #fff; /* ph */
    /* border: 1px solid #ddd; ph */
    /* border-radius: 2px; ph */
    padding: 1rem; /* ph*/
    margin: 1rem 0 0.5rem 0 /* ph */
}

form label {
    float: left;
    font-size: .9rem;
    margin: 0;
    padding: 0;

}

.right-label {
    float: right;
    cursor: pointer;

}

.left-label {
    text-align: center;
    cursor: pointer
}

input {
    width: 15rem;
    padding: .3rem;
    border-radius: 5px;
    outline: none;
    border: none
}

#sub_btn {
    display: block;
    width: 100%;
    padding: .3rem;
    border: none;
    background: #4fb823;
    color: #fff;
    border-radius: 3px;
}

#sub_btn:hover {
    background: #333;
    transition: all .5s
}


/***    Register Page     ***/
#checkbox {
    width: 1rem
}

form span {
    /*font-size: .8rem*/
}

#reset_pass_lbl {
    float: left
}

/***    Home Page     ***/
.home-page-title {
    color: #222
}

.css-wmy1p7-ReactDropdownSelect {
    background: white !important;


}

.optionBtn {

    height: 30px;
    width: 70px;
    border-radius: 5px;
}

.showPasswordValidation {
    padding-top: 0.5rem;
    textAlign: left;
    background: lightgrey;
    margin-top: 0.5rem;
}

ul.no-bullets {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

/*** marketing ***/
@font-face {
    font-family: "A Love of Thunder";
    src: url("assets/webfonts/ALoveOfThunder/A-Love-of-Thunder.eot?#iefix");
    src: url("assets/webfonts/ALoveOfThunder/A-Love-of-Thunder.eot?#iefix") format("eot"),
    url("assets/webfonts/ALoveOfThunder/A-Love-of-Thunder.woff2") format("woff2"),
    url("assets/webfonts/ALoveOfThunder/A-Love-of-Thunder.woff") format("woff"),
    url("assets/webfonts/ALoveOfThunder/A-Love-of-Thunder.ttf") format("truetype"),
    url("assets/webfonts/ALoveOfThunder/A-Love-of-Thunder.svg#ALoveOfThunder") format("svg");
}

@font-face {
    font-family: "Brandon Grotesque";
    src: url("assets/webfonts/BrandonGrotesque/Brandon_reg.eot?#iefix");
    src: url("assets/webfonts/BrandonGrotesque/Brandon_reg.eot?#iefix") format("eot"),
    url("assets/webfonts/BrandonGrotesque/Brandon_reg.woff2") format("woff2"),
    url("assets/webfonts/BrandonGrotesque/Brandon_reg.woff") format("woff"),
    url("assets/webfonts/BrandonGrotesque/Brandon_reg.ttf") format("truetype"),
    url("assets/webfonts/BrandonGrotesque/Brandon_reg.svg?#BrandonRegular") format("svg");
}

@font-face {
    font-family: "Brandon Grotesque";
    src: url("assets/webfonts/BrandonGrotesque/Brandon_med.eot?#iefix");
    src: url("assets/webfonts/BrandonGrotesque/Brandon_med.eot?#iefix") format("eot"),
    url("assets/webfonts/BrandonGrotesque/Brandon_med.woff2") format("woff2"),
    url("assets/webfonts/BrandonGrotesque/Brandon_med.woff") format("woff"),
    url("assets/webfonts/BrandonGrotesque/Brandon_med.ttf") format("truetype"),
    url("assets/webfonts/BrandonGrotesque/Brandon_med.svg?#BrandonMedium") format("svg");
    font-weight: 500;
}

@font-face {
    font-family: "Brandon Grotesque";
    src: url("assets/webfonts/BrandonGrotesque/Brandon_med_it.eot?#iefix");
    src: url("assets/webfonts/BrandonGrotesque/Brandon_med_it.eot?#iefix") format("eot"),
    url("assets/webfonts/BrandonGrotesque/Brandon_med_it.woff2") format("woff2"),
    url("assets/webfonts/BrandonGrotesque/Brandon_med_it.woff") format("woff"),
    url("assets/webfonts/BrandonGrotesque/Brandon_med_it.ttf") format("truetype"),
    url("assets/webfonts/BrandonGrotesque/Brandon_med_it.svg?#BrandonMediumItalic") format("svg");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Brandon Grotesque";
    src: url("assets/webfonts/BrandonGrotesque/Brandon_bld.eot?#iefix");
    src: url("assets/webfonts/BrandonGrotesque/Brandon_bld.eot?#iefix") format("eot"),
    url("assets/webfonts/BrandonGrotesque/Brandon_bld.woff2") format("woff2"),
    url("assets/webfonts/BrandonGrotesque/Brandon_bld.woff") format("woff"),
    url("assets/webfonts/BrandonGrotesque/Brandon_bld.ttf") format("truetype"),
    url("assets/webfonts/BrandonGrotesque/Brandon_bld.svg?#BrandonBold") format("svg");
    font-weight: 700;
}

@font-face {
    font-family: "Brandon Grotesque";
    src: url("assets/webfonts/BrandonGrotesque/Brandon_blk.eot?#iefix");
    src: url("assets/webfonts/BrandonGrotesque/Brandon_blk.eot?#iefix") format("eot"),
    url("assets/webfonts/BrandonGrotesque/Brandon_blk.woff2") format("woff2"),
    url("assets/webfonts/BrandonGrotesque/Brandon_blk.woff") format("woff"),
    url("assets/webfonts/BrandonGrotesque/Brandon_blk.ttf") format("truetype"),
    url("assets/webfonts/BrandonGrotesque/Brandon_blk.svg?#BrandonBlack") format("svg");
    font-weight: 900;
}

.form-control {
    border: 2px solid #000!important;
    border-radius: 0!important;
}
.ft-checkbox {
    border-radius: 0;
    border: 1px solid #000;
    float: left;
    width: auto;
    margin-top: 0.3rem;
    margin-right: 0.3rem;
}

#root {
    width: 580px;
    max-width: 100%;
    margin: 0 auto;
}

form {
    width: 100%;
}

@media screen and (min-width: 600px) {

    form {
        border: 1px solid #ddd;
        border-radius: 2px;
        padding: 2rem;
        margin: 2rem 0 1rem 0;
    }
}

#deleteProfile a{
    color: #42a618;
    text-decoration: none;
}

.pointer {cursor: pointer;}

.react-datepicker-wrapper {
    display: block!important;
    padding: 0;
    border: 0;
}
